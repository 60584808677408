// eslint-disable-next-line import/prefer-default-export
export const ObjectToQuery = ob => {
  const data = [];
  function iter(o, path) {
    if (Array.isArray(o)) {
      o.forEach(a => {
        iter(a, `${path}[]`);
      });

      return;
    }
    if (o !== null && typeof o === 'object') {
      Object.keys(o).forEach(k => {
        iter(o[k], `${path}[${k}]`);
      });

      return;
    }
    if (o === null || o === undefined) {
      return;
    }
    data.push(`${path}=${o}`);
  }

  Object.keys(ob).forEach(k => {
    iter(ob[k], k);
  });

  return data.join('&');
};
